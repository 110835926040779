module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"typekit":{"id":"vft1ocs"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Vooom","short_name":"Vooom","start_url":"/","background_color":"#4786e6","theme_color":"#4786e6","display":"minimal-ui","icon":"src/images/vooom-icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-rollbar/gatsby-browser.js'),
      options: {"plugins":[],"accessToken":"b70f872a600b4115b8c9788566dfb489","captureUncaught":true,"captureUnhandledRejections":true,"payload":{"environment":"production","host":"production-en"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
